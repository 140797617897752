import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import _ from "lodash";
import router from "../router/index";
import Swal from "sweetalert2";

Vue.use(Vuex);
Vue.config.devtools = true;

export default new Vuex.Store({
  state: {
    headerShowMinimal: false,
    routeParams: null,
    loader: true,
    showPage: false,
    data: [],
    dataOriginal: [],
    items: [],
    itemsPaginate: [],
    valorSemImposto: 0,
    valorComImposto: 0,
    loaderOpacity: false,
    loaderCheck: false,
    modalSaveSucess: false,
    modalSendSucess: false,
    modalSend: false,
    permiteVenderUnidade: false,
    vPage: null,
    flagControl: true,
    filtros: {
      segmento: {
        label: "Segmento",
        options: [],
      },
      linha: {
        label: "Linha",
        options: [],
      },
      grupo: {
        label: "Grupo",
        options: [],
      },
      subgrupo: {
        label: "Subgrupo",
        options: [],
      },
      descFornecedor: {
        label: "Fabricante",
        options: [],
      },
    },
    filtrosAtivos: {
      segmento: "",
      linha: "",
      grupo: "",
      subgrupo: "",
      descFornecedor: "",
      busca: "",
    },
    orderItems: "", // "ref" | "desc" | ""
    showFilter: false,
    shouldSetQuantityToZero: false,
  },
  mutations: {
    setOrdersItems(state, payload) {
      state.orderItems = payload;
    },
    setShouldSetQuantityToZero(state) {
      state.shouldSetQuantityToZero = true;
    },
    setHeaderShowMinimal(state, payload) {
      state.headerShowMinimal = payload;
    },
    updateItem(state, payload) {
      const positionItem = state.data.items.findIndex(
        (item) => item.codigo === payload.codigo
      );

      state.data.items[positionItem].segregacoes = _.cloneDeep(
        state.dataOriginal.items[positionItem].segregacoes
      );
    },
    updateVPage(state, payload) {
      state.vPage = payload;
    },
    setItems(state, payload) {
      state.items = payload;
    },
    setPermiteVenderUnidade(state, payload) {
      state.permiteVenderUnidade = payload;
    },
    setModalSend(state, payload) {
      state.modalSend = payload;
    },
    setModalSendSucess(state, payload) {
      state.modalSendSucess = payload;
    },
    setModalSaveSucess(state, payload) {
      state.modalSaveSucess = payload;
    },
    setLoaderCheckShow(state) {
      state.loaderCheck = true;
    },
    setLoaderCheckHide(state) {
      state.loaderCheck = false;
    },

    setLoaderOpacityShow(state) {
      state.loaderOpacity = true;
    },
    setLoaderOpacityHide(state) {
      state.loaderOpacity = false;
    },
    setLoaderShow(state) {
      state.loader = true;
    },
    setLoaderHide(state) {
      state.loader = false;
    },
    setShowPage(state) {
      state.showPage = true;
    },
    setHidePage(state) {
      state.showPage = false;
    },

    setItemsPaginate(state, payload) {
      state.itemsPaginate = payload;
    },

    setData(state, payload) {
      state.data = payload;
    },
    setDataOriginal(state, payload) {
      state.dataOriginal = payload;
    },
    setQuantityItem(state) {
      state.valorComImposto = state.data.items
        .map((item) => {
          return item.precoComImpostos * item.quantidade;
        })
        .reduce((acc, news) => acc + news, 0);

      state.valorSemImposto = state.data.items
        .map((item) => {
          return item.preco * item.quantidade;
        })
        .reduce((acc, news) => acc + news, 0);
    },
    setValores(state, payload) {
      state.valorSemImposto = payload.valorSemImposto;
      state.valorComImposto = payload.valorComImposto;
    },
    resetSegregation(state, payload) {
      state.data.items[payload.positionArray].segregacoes =
        state.dataOriginal.items[payload.positionArray].segregacoes;
    },
    eraseAllQuantity(state) {
      for (let count = 0; count < state.data.items.length; count++) {
        if (state.data.items[count].segregacoes.length) {
          state.data.items[count].segregacoes = state.data.items[
            count
          ].segregacoes.map((seg) => ({
            ...seg,
            quantidade: 0,
            volatil: 0,
            action: false,
            actionSegregation: false,
          }));
          state.data.items[count].quantidade = 0;
        } else {
          state.data.items[count].quantidade = 0;
        }
      }

      state.valorComImposto = 0;
      state.valorSemImposto = 0;
    },
    refreshAllQuantity(state) {
      for (let count = 0; count < state.data.items.length; count++) {
        const segregacaoOriginal = _.cloneDeep(
          state.dataOriginal.items[count].segregacoes
        );
        const quantidadeOriginal = _.cloneDeep(
          state.dataOriginal.items[count].quantidadeOriginal
        );

        if (state.data.items[count].segregacoes.length) {
          state.data.items[count].segregacoes = segregacaoOriginal;
          state.data.items[count].quantidade = quantidadeOriginal;
        } else {
          state.data.items[count].quantidade = quantidadeOriginal;
        }
      }
      state.valorComImposto = state.data.items
        .map((item) => item.precoComImpostos * item.quantidade)
        .reduce((acc, news) => acc + news, 0);
      state.valorSemImposto = state.data.items
        .map((item) => item.preco * item.quantidade)
        .reduce((acc, news) => acc + news, 0);
    },
    setFiltersArray(state, payload) {
      state.filtros = payload;
    },
    setCurrentFilter(state, payload) {
      state.filtrosAtivos = {...state.filtrosAtivos, ...payload};
    },
    updateSearch(state, payload) {
      state.filtrosAtivos.busca = payload;
    },
    setShowFilter(state, payload) {
      state.showFilter = payload;
    },
    resetAllQuantity(state) {
      state.data.items = state.data.items.map((item) => {
        if (item.segregacoes.length) {
          item.segregacoes = item.segregacoes.map((seg) => ({
            ...seg,
            quantidade: 0,
            volatil: 0,
            action: false,
            actionSegregation: false,
          }));
          item.quantidade = 0;
          item.quantidadeOriginal = 0;
        } else {
          item.quantidade = 0;
          item.quantidadeOriginal = 0;
        }

        return item;
      });

      state.items = state.data.items;
      state.itemsPaginate = state.itemsPaginate.map((item) => {
        if (item.segregacoes.length) {
          item.segregacoes = item.segregacoes.map((seg) => ({
            ...seg,
            quantidade: 0,
            volatil: 0,
            action: false,
            actionSegregation: false,
          }));
          item.quantidade = 0;
          item.quantidadeOriginal = 0;
        } else {
          item.quantidade = 0;
          item.quantidadeOriginal = 0;
        }

        return item;
      });
    }
  },

  actions: {
    async getData({ commit, state }, data) {
      commit("setLoaderShow");
      let isB2B;

      try {
        let response = null;
        if (
          data.tradeAgent === undefined ||
          data.tradeAgent === "undefined" ||
          data.cpfCnpjCustomer === undefined ||
          data.cpfCnpjCustomer === "undefined"
        ) {
          document.title = "Guarani | Orçamento Web";
          response = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/orders/client/${data.cpfCnpj}/${data.numPedido}`,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json; charset=UTF-8",
              },
            }
          );

          isB2B = false;
        } else {
          document.title = "Guarani | Loja B2B";
          response = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/b2b/order/customer/${data.cpfCnpj}/${data.numPedido}/${data.tradeAgent}/${data.cpfCnpjCustomer}`,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json; charset=UTF-8",
              },
            }
          );

          isB2B = true;
        }

        var d = new Date();
        var date = new Date(
          response.data.dataExpira
            .split("/")
            .reverse()
            .join("-")
        );

        if (date <= d) {
          router.push("/expirado");
          commit("setLoaderHide");
          return;
        }

        const shouldZeroQuantity = localStorage.getItem("shouldZeroQuantity");
        const showResetQuantityModal = localStorage.getItem("showResetQuantityModal");

        if (isB2B) {
          // verificar se possui algum item com quantidade não zerada
          const hasItemsWithQuantity = response.data.items.some((item) => Number(item.quantidade) > 0);

          if (hasItemsWithQuantity && showResetQuantityModal !== "false") {
            // exibir modal de zerar quantidade dos itens
            commit("setShouldSetQuantityToZero");
          }
        }

        if (response.data.imagemEmpresa) {
          response.data.imagemEmpresa = response.data.imagemEmpresa.startsWith("http") ? encodeURI(response.data.imagemEmpresa) : `data:image/png;base64,${response.data.imagemEmpresa}`;
        }

        let newQuantity = response.data.items.map((item, index) => ({
          ...item,
          imagem: item.imagem ? item.imagem.startsWith("http") ? encodeURI(item.imagem) : `data:image/png;base64,${item.imagem}` : "",
          quantidadeOriginal: showResetQuantityModal ? 0 : item.quantidade,
          positionArray: index,
          qtdSelecaoSegregacaoMax: item.qtdSelecaoSegregacaoMax
            ? item.qtdSelecaoSegregacaoMax
            : 0,
        }));

        const filters = _.cloneDeep(state.filtros);
        for (let i = 0; i < response.data.items.length; i++) {
          const item = response.data.items[i];

          for (const propriedade in item.detalhesProduto) {
            if (filters.hasOwnProperty(propriedade)) {
              if (!filters[propriedade].options.includes(item.detalhesProduto[propriedade])) {
                filters[propriedade].options.push(item.detalhesProduto[propriedade]);
              }
            }
          }
        }
        commit("setFiltersArray", filters);

        let newQuantitySegregacao = newQuantity.map((item) => {
          let segregacao = item.segregacoes.map((seg) => {
            return {
              ...seg,
              imagem: seg.imagem
                ? seg.imagem
                : require("@/assets/images/placeholder-produto.png"),
              multiplo: seg.multiplo ? seg.multiplo : 0,
              action: seg.quantidade === 0 ? false : true,
              actionSegregation: seg.quantidade === 0 ? false : true,
              volatil: Number(seg.quantidade),
              disabled: false,
            };
          });

          if (item.segregacoes.length) {
            return {
              ...item,
              segregacoes: [...segregacao],
              total: 0,
            };
          } else {
            return {
              ...item,
              segregacoes: [],
              total: 0,
            };
          }
        });

        response.data.items = newQuantitySegregacao;

        state.valorComImposto = response.data.items
          .map((item) => item.precoComImpostos * item.quantidade)
          .reduce((acc, news) => acc + news, 0);
        state.valorSemImposto = response.data.items
          .map((item) => item.preco * item.quantidade)
          .reduce((acc, news) => acc + news, 0);

        commit("setPermiteVenderUnidade", response.data.permiteVenderUnidade);
        commit("setData", response.data);
        state.items = response.data.items;

        if (shouldZeroQuantity === "true" && isB2B) {
          commit("eraseAllQuantity");
        }
        
        commit("setDataOriginal", _.cloneDeep(state.data));

        commit("setLoaderHide");
        commit("setShowPage");
      } catch (error) {
        console.log(error);
        router.push("/error");
        commit("setLoaderHide");
      }
    },

    async saveAndSendEstimate(state, payload) {
      const {
        cpfCnpj,
        numPedido,
        tradeAgent,
        cpfCnpjCustomer,
      } = router.currentRoute.params;
      let datas = this.state.data;
      let dataOriginal = this.state.dataOriginal;

      let objectMerge = _.cloneDeep(Object.assign(dataOriginal, datas));

      for (let counter = 0; counter < objectMerge.items.length; counter++) {
        if (objectMerge.items[counter].hasOwnProperty("segregacoesOriginal")) {
          delete objectMerge.items[counter].segregacoesOriginal;
        }

        if (objectMerge.items[counter].hasOwnProperty("quantidadeOriginal")) {
          delete objectMerge.items[counter].quantidadeOriginal;
        }

        if (objectMerge.items[counter].hasOwnProperty("frontImage")) {
          delete objectMerge.items[counter].frontImage;
        }

        if (objectMerge.items[counter].hasOwnProperty("originalQtde")) {
          delete objectMerge.items[counter].originalQtde;
        }

        if (objectMerge.items[counter].hasOwnProperty("positionArray")) {
          delete objectMerge.items[counter].positionArray;
        }

        if (objectMerge.items[counter].hasOwnProperty("total")) {
          delete objectMerge.items[counter].total;
        }

        if (objectMerge.items[counter].segregacoes.length) {
          for (
            let cont = 0;
            cont < objectMerge.items[counter].segregacoes.length;
            cont++
          ) {
            if (
              objectMerge.items[counter].segregacoes[cont].hasOwnProperty(
                "action"
              )
            ) {
              delete objectMerge.items[counter].segregacoes[cont].action;
            }

            if (
              objectMerge.items[counter].segregacoes[cont].hasOwnProperty(
                "actionSegregation"
              )
            ) {
              delete objectMerge.items[counter].segregacoes[cont]
                .actionSegregation;
            }

            if (
              objectMerge.items[counter].segregacoes[cont].hasOwnProperty(
                "originalQtde"
              )
            ) {
              delete objectMerge.items[counter].segregacoes[cont].originalQtde;
            }

            if (
              objectMerge.items[counter].segregacoes[cont].hasOwnProperty(
                "volatil"
              )
            ) {
              delete objectMerge.items[counter].segregacoes[cont].volatil;
            }
          }
        }
      }

      if (payload === "save") {
        this.commit("setLoaderOpacityShow");

        try {
          let response = null;
          if (
            tradeAgent === undefined ||
            tradeAgent === "undefined" ||
            cpfCnpjCustomer === undefined ||
            cpfCnpjCustomer === "undefined"
          ) {
            response = await axios.post(
              `${process.env.VUE_APP_BASE_URL}/orders/app/${cpfCnpj}/${numPedido}`,
              objectMerge
            );
          } else {
            response = await axios.post(
              `${process.env.VUE_APP_BASE_URL}/b2b/order/customer/${cpfCnpj}/${numPedido}/${tradeAgent}/${cpfCnpjCustomer}`,
              objectMerge
            );
          }
          if (
            response.data === "Import success" ||
            response.data === "Successfully imported"
          ) {
            const toSave = _.cloneDeep(state.state.data);
            for (let count = 0; count < toSave.items.length; count++) {
              toSave.items[count].quantidadeOriginal =
                toSave.items[count].quantidade;
            }

            state.state.dataOriginal.items = _.cloneDeep(toSave.items);
            state.state.data.items = _.cloneDeep(toSave.items);

            this.commit("setLoaderOpacityHide");
            Vue.toast("Orçamento salvo com sucesso", {
              id: "my-toast",

              className: ["my-toast", "green"],
              horizontalPosition: "center",
              verticalPosition: "top",
              duration: 3500,
              mode: "queue",
              transition: "fade",
              closeable: false,
            });
          } else {
            this.commit("setLoaderOpacityHide");
            Vue.toast(`Error ! ${response.data}`, {
              id: "my-toast",

              className: ["my-toast", "toast-warning"],
              horizontalPosition: "center",
              verticalPosition: "top",
              duration: 3500,
              mode: "queue",
              transition: "fade",
              closeable: false,
            });
          }
        } catch (error) {
          this.commit("setLoaderOpacityHide");
          Vue.toast(`Error ! ${error}`, {
            id: "my-toast",

            className: ["my-toast", "toast-warning"],
            horizontalPosition: "center",
            verticalPosition: "top",
            duration: 3500,
            mode: "queue",
            transition: "fade",
            closeable: false,
          });
        }
      }

      if (payload === "send") {
        this.commit("setLoaderOpacityShow");

        try {
          let response = null;
          if (
            tradeAgent === undefined ||
            tradeAgent === "undefined" ||
            cpfCnpjCustomer === undefined ||
            cpfCnpjCustomer === "undefined"
          ) {
            response = await axios.post(
              `${process.env.VUE_APP_BASE_URL}/orders/client/${cpfCnpj}/${numPedido}`,
              objectMerge
            );
          } else {
            response = await axios.post(
              `${process.env.VUE_APP_BASE_URL}/b2b/order/company/${cpfCnpj}/${numPedido}/${tradeAgent}/${cpfCnpjCustomer}`,
              objectMerge
            );
          }

          if (
            response.data === "Import success" ||
            response.data === "Successfully imported"
          ) {
            this.commit("setLoaderOpacityHide");
            this.commit("setModalSendSucess", true);
            localStorage.removeItem("shouldZeroQuantity");
            localStorage.removeItem("showResetQuantityModal");
          } else {
            this.commit("setLoaderOpacityHide");
            Swal.fire({
              title: "Error!",
              text: response.data,
              icon: "error",
              confirmButtonText: "Fechar",
            });
          }
        } catch (error) {
          this.commit("setLoaderOpacityHide");

          Swal.fire({
            title: "Error!",
            text: error,
            icon: "error",
            confirmButtonText: "Fechar",
          });
        }
      }
    },
  },
  getters: {
    getItems(state) {
      return state.data.items;
    },
  },
  modules: {},
});
